@import "../../Assets/Styles/_vars.scss";
@import "../../Assets/Styles/_mixins.scss";

.identity {
  .identitydata {
    text-align: center;
    padding: 35px;
    svg {
      width: 65px;
      height: 65px;
      margin-top: 30px;
      fill: $pendingtext;
    }

    h2 {
      color: $tabletext;
      @include fluid-type(320px, 1920px, 20px, 22px);
      font-weight: 600;
      padding-top: 20px;
    }

    p {
      @include fluid-type(320px, 1920px, 14px, 16px);
      color: $text-80838E;
      padding-top: 8px;
      padding-bottom: 48px;
    }

    Button {
      width: 100%;
      margin-top: 0px !important;
    }
  }
}

.profileKycSec {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  padding: 60px 0;
  h4 {
    color: $darktext;
    font-weight: 600;
    @include fluid-type(320px, 1920px, 16px, 24px);
  }
  p {
    color: $text-80838E;
    @include fluid-type(320px, 1920px, 12px, 14px);
    max-width: 316px;
    width: 100%;
    text-align: center;
  }
}
