@import "../../Assets/Styles/_vars.scss";
@import "../../Assets/Styles/_mixins.scss";

.cointdetailPage {
  &_headBar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 18px 40px 24px 50px;
    @media (max-width: 767px) {
      padding: 15px;
    }
    h3 {
      img {
        padding-right: 20px;
      }

      span {
        color: $text-80838E;
        @include fluid-type(320px, 1920px, 14px, 20px);
        padding-left: 5px;
      }
    }

    button {
      border-radius: 25px;
      box-shadow: 0px 0px 6px #0000000f;
      height: 40px;
      &:hover{
        border-color: #f0b90b;
        color: #f0b90b;
      }
    }
  }

  &_tabs {
    padding: 0px 40px 40px 0px;
    position: relative;
    @media (max-width: 767px) {
      padding-right: 25px;
    }

    &:after {
      content: "";
      position: absolute;
      border-top: 1px solid $border-light-gray-F3F3F3;
      width: 100%;
      top: 62px;
      left: 0;
      @media (max-width: 1366px) {
        top: 59px;
      }
      @media (max-width: 768px) {
        top: 57px;
      }
    }

    .mt {
      margin-top: 30px;
    }

    &_overView {
      &_graphsec {
        &_duration {
          align-items: center;
          justify-content: space-between;
          margin-bottom: 77px;
          padding: 36px 40px 0 40px;
          @media (max-width: 768px) {
            padding: 10px 20px 0 20px;
          }
          h3 {
            @include fluid-type(320px, 1920px, 20px, 36px);

            span {
              @include fluid-type(320px, 1920px, 22px, 28px);
            }

            sup {
              @include fluid-type(320px, 1920px, 16px, 20px);
              color: #098551;
            }
          }

          button {
            border: none;
            background-color: transparent;
            color: $text-80838E;

            &:active,
            &:hover {
              color: #1db5e2;
            }
          }
        }

        &_graph {
          height: 221px;
          width: 100%;
        }

        h4 {
          @include fluid-type(320px, 1920px, 16px, 20px);
          font-weight: bold;
          color: $darktext;
        }

        &_points {
          border-bottom: 0.5px solid $border-light-gray-F3F3F3;
          border-top: 0.5px solid $border-light-gray-F3F3F3;
          padding: 20px 40px;
          @media (max-width: 767px) {
            padding: 20px;
          }
        }

        &_textline {
          padding: 20px 40px;
          @media (max-width: 767px) {
            padding: 20px;
          }
          h5 {
            color: $text-80838E;
            @include fluid-type(320px, 1920px, 12px, 16px);
          }

          p {
            padding-bottom: 30px;
            color: $text-80838E;
            @include fluid-type(320px, 1920px, 12px, 14px);
          }

          a {
            display: block;
            max-width: 150px;
            margin: 16px 0;
            color: $baseblue;

            &:hover,
            &:focus {
              color: $text-80838E;
            }

            img {
              margin-right: 14px;
            }

            span {
              vertical-align: middle;
            }
          }
        }
      }
    }

    &_balance {
      h4 {
        @include fluid-type(320px, 1920px, 16px, 20px);
        padding: 30px 40px 20px 40px;
        border-bottom: 1px solid $border-light-gray-F3F3F3;
        font-weight: 600;
      }

      &_flex {
        align-items: center;
        justify-content: space-between;
        padding: 16px 40px;
        border-bottom: 1px solid $border-light-gray-F3F3F3;

        &:last-child {
          border-bottom: none;
        }

        h5 {
          font-weight: 600;
          @include fluid-type(320px, 1920px, 12px, 16px);

          span {
            color: $text-80838E;
            @include fluid-type(320px, 1920px, 12px, 14px);
            font-weight: 400;
          }
        }

        &_main {
          display: flex;
          align-items: center;
          justify-content: space-between;

          img {
            padding: 0 20px;
          }

          @media (max-width: 768px) {
            // flex-direction: column;
          }
        }
      }
    }

    &_cardtab {
      margin-top: 90px;
      @media (max-width: 1200px) {
        margin-top: 40px;
        margin-left: 50px;
      }
      @media (max-width: 767px) {
        margin-left: 15px;
      }
    }
  }
 
}
 .coin_bls {
    text-align: right !important;
  }
.graph__section {
  padding: 0;
}
.point {
  margin: 20px 0;
  @media (max-width: 767px) {
    margin: 10px 0;
  }
  h6 {
    @include fluid-type(320px, 1920px, 12px, 16px);
    color: $text-80838E;
    white-space: nowrap;

    img {
      margin-left: 8px;
    }
  }

  &_mainText {
    @include fluid-type(320px, 1920px, 12px, 16px);
    font-weight: 500;
    color: $darktext;
    padding: 10px 0 12px 0;
  }
}
.paddingLeft {
  padding-left: 50px;
  @media (max-width: 767px) {
    padding-left: 15px;
  }
}
.scrolledEleMob {
  overflow: auto;
  > div {
    min-width: 500px;
  }
}
