@import "../../../Assets/Styles/_mixins.scss";
@import "../../../Assets/Styles/_vars.scss";

.points {
    background-color: #7B7B7B0F;
    padding: 24px;
    border-radius: 14px;

    h6 {
        @include fluid-type(320px, 1920px, 14px, 14px);
        margin-bottom: 6px;
    }

    p {
        @include fluid-type(320px, 1920px, 12px, 12px);
        margin-bottom: 6px;
        color: #80838E;

        strong {
            color: black;
        }

        &:last-child {
            margin-bottom: unset
        }
    }
}

.copy {
    background-color: #e4bd2233;
    padding: 12px 24px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    border-radius: 14px;
    margin: 15px 0;

    h4 {
        @include fluid-type(320px, 1920px, 14px, 16px);
        color: black;

        span {
            color: #80838E;
        }
    }
}

.content {
    padding: 32px;
}