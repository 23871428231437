@import "../../Assets/Styles/_mixins.scss";
@import "../../Assets/Styles/_vars.scss";

.port {
  padding: 30px 38px 30px 50px;
  flex: 1;

  @media (max-width: 1366px) {
    padding: 20px;
  }
  @media (max-width: 767px) {
    padding: 20px 15px;
  }

  .row {
    @media (max-width: 991px) {
      gap: 20px;
    }
  }

  .heading {
    max-width: 1123px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;

    :first-child {
      color: $borderdarkgrey;
      @include fluid-type(320px, 1920px, 14px, 20px);
      margin-bottom: 20px;
    }

    :nth-child(2) {
      color: $baseblue;
      @include fluid-type(320px, 1920px, 14px, 20px);
      margin-bottom: 20px;
    }

    h4 {
      color: $darktext;
      font-weight: 600;
    }
  }

  &__leftData {
    background-color: $white;
    box-shadow: 0px 0px 12px $tableborders;
    border-radius: 20px;
    overflow: hidden;

    .buyData {
      display: flex;
      gap: 8px;

      .deposit {
        border: 1px solid $bordergrey;
        border-radius: 20px;
        color: $borderdarkgrey;
        display: flex;
        justify-content: center;
        padding: 6.5px;
        max-width: 122px;
        width: 100%;
        align-items: center;
        gap: 7px;

        &:hover,
        :active,
        :focus-within {
          background-color: $baseblue;
          color: $white;
          transition: all 0.1s ease-in-out;

          svg {
            filter: brightness(0) invert(1);
          }
        }
      }
    }
  }

  &__portfolio {
    width: 100%;
    // &__table {
    //     max-width: calc(100% - 457px);
    //     width: 100%;

    //     @media (max-width:1366px) {
    //         max-width: calc(100% - 330px)
    //     }

    //     @media (max-width:991px) {
    //         max-width: 100%;
    //         width: 100%;
    //     }

    // }

    &__reward {
      width: 100%;
      height: 100%;
      background-color: $white;
      box-shadow: 0px 0px 12px $tableborders;
      border-radius: 20px;

      // padding: 16px 24px 0px 24px;
      a {
        @include fluid-type(320px, 1920px, 14px, 20px);
        color: $link;
        font-weight: 600;
      }
    }
  }
}

.Buy {
  padding: 50px 20px 22px 20px;
  position: relative;
  
  @media (max-width: 575px) {
    padding: 20px 0;
  }

  &_row {
    justify-content: center;
    position: relative;
    .errorText {
      bottom: -27px;
      position: absolute;
    }

    p {
      border: none;
      text-align: center;
      color: $text-80838E;

      span {
        width: 32px;
        background-color: $bg-light-gray-F3F3F3;
        height: 32px;
        border-radius: 50%;
        border: 1px solid white;
        padding-bottom: 5px;
        display: inline-block;

        img {
          padding: 7px;
        }
      }
    }
  }

  &_text {
    text-align: center;
    max-width: 300px;
    margin: auto;
    padding: 25px 0;
  }

  div + a {
    text-align: center;
    display: block;
    color: $baseblue;
    background-color: $bg-light-blue-E9F5FF;
    width: 196px;
    padding: 15px 20px;
    margin: auto;
    border-radius: 30px;
    margin: 25px auto 28px;

    img {
      margin-right: 8px;
    }
  }

  &_btn {
    .convertIcon{
      @media (max-width:991px) {
        flex-direction: column;
      }
    }
    ul {
      padding: 0;
      margin-bottom: 0;

      li {
        width: 100%;
        background-color: transparent;
        border: none;
        padding: 17px;
        list-style: none;
        border-bottom: 1px solid $border-light-gray-F3F3F3;

        &:last-child {
          border-bottom: none;
          position: relative;
        }

        span {
          display: inline-block;
          width: 33.3%;
          white-space: nowrap;

          &:first-child {
            text-align: left;
            color: $text-80838E;
          }

          &:last-child {
            text-align: right;
            color: $text-80838E;
          }
        }
      }
    }

    &_icon {
      width: 23px;
      margin-right: 6px;
    }
  }

  hr {
    margin: 0;
    border: 0.2px solid red;
  }

  &_btnMain {
    button {
      width: 100%;
      margin: 24px 0 11px;
    }
  }

  &_amount {
    display: flex;
    justify-content: space-between;
  }

  div > span {
    color: $text-80838E;
  }
}

.btnList {
  ul {
    li {
      list-style: none;
      margin-bottom: 36px;

      img {
        display: block;
        float: left;
        width: 44px;
        height: 44px;
        margin-right: 8px;
      }

      h6 {
        @include fluid-type(320px, 1920px, 12px, 16px);
        font-weight: 600;
        line-height: initial;
        padding-top: 4px;

        span {
          // @include fluid-type(320px, 1920px, 10px, 14px);
          color: $text-80838E;
          font-weight: 400;
          // line-height: 19px;
        }
      }
    }
  }

  &_heading {
    padding: 25px 20px;
    position: sticky;
    top: 0;
    background: $white;
    z-index: 9;
    border-radius: 20px 20px 0 0;

    h5 {
      text-align: center;
      @include fluid-type(320px, 1920px, 12px, 16px);
      font-weight: 600;
    }

    button {
      border: none;
      background-color: transparent;
      float: left;
      position: absolute;
      left: 15px;
      top: 50%;
      transform: translateY(-50%);
      margin: 0 !important;
      width: auto !important;
    }
  }
}

.PayWith {
  &_body {
    padding: 40px;

    &_list {
      align-items: flex-start;
      justify-content: space-between;
      background-color: rgba(243, 243, 243, 0.5);
      padding: 20px;
      border-radius: 14px;
      margin-bottom: 20px;

      p {
        color: $text-80838E;

        span {
          color: $baseblue;
        }
      }

      &_dflex {
        display: flex;
        align-items: flex-start;

        img {
          margin-right: 8px;
        }

        h6 {
          @include fluid-type(320px, 1920px, 12px, 16px);
          font-weight: 600;
          margin-bottom: 0;

          span {
            margin-top: 10px;
            color: $text-80838E;
            font-weight: 400;
          }
        }
      }
    }
  }
}

.Asset {
  &_body {
    padding: 10px 40px 40px 40px;
    max-height: 543px;
    overflow-y: scroll;

    &_list {
      align-items: center;
      justify-content: space-between;
      margin-bottom: 10px;
      padding: 13px 20px;
      border-radius: 14px;

      &.active {
        background-color: $bg-light-gray-F3F3F3;
      }

      &:hover,
      .active {
        background-color: $bg-light-gray-F3F3F3;
      }

      &_imgSec {
        h6 {
          line-height: initial;
          font-weight: 600;
          @include fluid-type(320px, 1920px, 12px, 16px);
          display: flex;
          align-items: center;

          .shortName {
            font-weight: 400;
            color: $text-80838E;
            @include fluid-type(320px, 1920px, 12px, 14px);
          }
        }

        img {
          float: left;
          margin-right: 8px;

          @media (max-width: 767px) {
            width: 30px;
            height: 30px;
          }
        }
      }

      p {
        margin-bottom: 0;
        line-height: initial;
        font-weight: 600;
        @include fluid-type(320px, 1920px, 12px, 16px);

        span {
          font-weight: 400;
          color: $text-80838E;
          @include fluid-type(320px, 1920px, 12px, 14px);
        }
      }

      &_check {
        display: flex;
        align-items: center;
      }
    }

    &_search {
      margin-bottom: 0;
      position: relative;

      input {
        margin: 0 !important;
        padding-left: 45px;
        height: 40px !important;
      }
    }
  }
}

/*custom tab...... scss*/

.positionedContent {
  position: absolute;
  inset: 0;
  z-index: 9;
  background: $white;
  border-radius: 20px;
  overflow-y: auto;
}

.withdrawalAddress {
  padding: 24px 0 0;

  p {
    @include fluid-type(320px, 1920px, 10px, 12px);
    color: $text-80838E;
    padding-bottom: 6px;

    span {
      color: $darktext;
      margin-right: 5px;
    }
  }

  h6 {
    @include fluid-type(320px, 1920px, 12px, 14px);
    padding-bottom: 6px;
    color: $darktext;
  }

  &_inputSec {
    position: relative;
    padding: 22px 0 16px 0;
    &.lastChild {
      padding-top: 0 !important;
      margin-bottom: 10px;
    }

    p {
      color: $baseblue;
      position: absolute;
      right: 7px;
      top: 30px;
    }
  }
}

.sendRecived {
  &_sendTab {
    button {
      margin: 24px 0 0 0;
    }
  }
}

.listBtn {
  ul {
    padding: 0;
    margin-bottom: 0;

    li {
      list-style: none;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 17px 18px;
      border-bottom: 1px solid $border-light-gray-F3F3F3;

      &:last-child {
        border-bottom: none;
      }

      span {
        width: 33.3%;
        color: $text-80838E;

        img {
          width: 23px;
          margin-right: 5px;
        }

        &:last-child {
          text-align: right;
        }

        &:nth-child(2) {
          color: black;
        }
      }
    }
  }
}

.recived {
  padding: 32px 20px 14px 20px;

  h6,
  p {
    padding-bottom: 6px;
  }

  &_input {
    padding: 12px 20px;
    border-radius: 14px;
    background-color: #e4bd2233;
    margin: 32px 0 24px;

    h6 {
      display: flex;
      justify-content: space-between;
    }
  }

  p + h6 {
    padding-top: 28px;
  }

  &_barCode {
    display: block;
    margin: 49px auto 0 auto;
    max-width: 185px;
    width: 100%;
  }
}

.pd0 {
  padding: 0px;
}

.addressModal {
  text-align: center;
  padding: 50px;

  p {
    max-width: 249px;
    margin: auto;
    padding: 26px 0 38px;
  }
}

.btnOver {
  text-align: center;
  padding-bottom: 25px;
}

.addressbook {
  padding: 30px 0;

  &_list {
    border-bottom: 1px solid $border-light-gray-F3F3F3;
    padding: 10px 30px;
    .radioSec {
      label {
        font-weight: 600;
      }
    }

    ul {
      margin-bottom: 0;
      padding: 0;

      li {
        list-style: none;
        font-weight: 600;
        color: black;
        display: flex;
        span {
          white-space: nowrap;
          width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        p {
          color: $text-80838E;
          margin-right: 16px;
          max-width: 68px;
          width: 100%;
          display: inline-block;
        }
      }
    }
  }

  button {
    display: block !important;
    margin: 30px auto 0 auto;
  }
}

.addAdress {
  padding: 30px 20px;

  button {
    margin: 24px auto 0;
    display: block !important;
    max-width: 180px;
    width: 100%;
  }
}

.AddressTable {
  flex: 1;
  &_top {
    padding: 24px;

    &_justifyContent {
      justify-content: space-between;
      align-items: flex-end;
      @media (max-width: 991px) {
        gap: 20px;
        > div {
          width: 100%;
        }
      }

      &_inline {
        display: flex;
        align-items: flex-end;
        gap: 30px;
        @media (max-width: 991px) {
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;
          gap: 12px;
          > div {
            width: 100%;
          }
        }

        .ant-input .input_amount {
          height: 40px;
        }

        &_select {
          width: 200px;
          // height: 50px;
          > div > div {
            margin-bottom: 0 !important;
          }
        }
      }
    }
  }

  .customSelect.ant-select {
    height: 40px !important;
  }
}

.addressbookIcon {
  position: absolute;
  right: 24px;
  top: 66%;
  transform: translateY(-50%);
  z-index: 1;
  cursor: pointer;
}

.errorTextInput {
  position: absolute;
  bottom: 4px;
  &.bottomO {
    bottom: -2px;
  }
}

.inputLike {
  &_outer {
    margin-bottom: 10px;
    &:first-child {
      margin-top: 10px;
    }
    p {
      @include fluid-type(320px, 1920px, 12px, 16px);
      margin-bottom: 8px !important;
      color: #000;
      font-weight: normal;
    }
    div {
      border: 1px solid rgba(188, 188, 188, 0.3137254902);
      opacity: 1;
      width: 100%;
      height: 40px;
      box-shadow: none;
      position: relative !important;
      background-color: transparent !important;
      border-radius: 50px !important;
      padding: 5px 24px;
      display: flex;
      align-items: center;
      cursor: pointer;
      img {
        position: absolute;
        right: 22px;
      }
    }
  }
}
.addressTable table tbody > tr {
  cursor: initial !important;
}
.deleteButton {
  background-color: transparent;
  border: 0;
  box-shadow: none;
  cursor: pointer;
  transition: 0.3s all;
  &:hover {
    color: $base-hover;
  }
}
.searchInput {
  position: relative;
  input {
    margin-bottom: 0 !important;
  }
  &:after {
    content: "";
    position: absolute;
    top: 50%;
    left: 20px;
    transform: translateY(-50%);
    z-index: 1;
    width: 15px;
    height: 15px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url("../../Assets/Images/SearchIcon.svg");
  }
}
.labelText {
  letter-spacing: 0px;
  color: $darktext;
  font-weight: 400;
  opacity: 1;
  width: 100%;
  text-align: left;
  display: flex;
  padding-top: 3px;
  padding-bottom: 8px;
  @include fluid-type(320px, 1920px, 12px, 14px);
}

.enableAuth {
  position: absolute;
  right: 0;
  color: $baseblue !important;
  cursor: pointer;
}
.coindata {
  padding-right: 25px;
}
.dropValue {
  border: 1px solid #bcbcbc;
  border-radius: 25px;
  padding: 10px;
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
    span:empty{
    display: none;
   }
}
.withdraw {
  padding: 0 !important;
  .cmn_drpListStyle {
    top: 100%;
    padding: 10px;
  }
}
.deposit .cmn_drpListStyle {
  top: calc(100% - 22px);
  padding: 0 20px;
  left: 0;
  @media (max-width:575px) {
    padding:0 10px;
  }
}
.cmn_drpListStyle {
  position: absolute;
  width: 100%;
  left: 10px;
  z-index: 999;
  @media (max-width:575px) {
    left:0;
  }

  > div {
    width: clamp(350px,50%, 360px);
    height: 250px;
    position: static;
    @media (max-width:991px) {
     width: 100%; 
     max-width: unset;
    }
    @media (max-width:480px) {
      min-width: 270px;
    }
    img {
      width: 30px;
      aspect-ratio: 1;
      height: auto;
    }
    &.networkDiv {
      margin-left: auto;
    }
  }
}

.labelTextSpan {
  font-weight: 400;
  display: block;
  // padding-bottom: 8px;
  color: $darktext;
  @include fluid-type(320px, 1920px, 12px, 14px);

}