@import "../../../Assets/Styles/_mixins.scss";
@import "../../../Assets/Styles/_vars.scss";

.banner {
  background-image: url("../../../Assets/Images/banner.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  padding: 25px 50px;
  border-radius: 20px;

  @media (max-width: 767px) {
    padding: 25px;
  }
  &_content {
    display: flex;
    justify-content: space-between;
    align-items: center;

    h4 {
      color: white;
      font-weight: 600;
      @include fluid-type(320px, 1920px, 14px, 20px);
    }

    button {
      color: #f0b90b;
      background-color: white;
      border-radius: 25px;
      padding: 14px 24px;
      height: auto;
      font-weight: 600;
      border-color: white;
      @include fluid-type(320px, 1920px, 12px, 16px);

      &:hover,
      &:active,
      &:focus {
        background-color: unset;
        border-color: white;
        color: white;
      }

      @media (max-width: 767px) {
        padding: 12px;
      }
    }
    button:hover img {
      filter: brightness(0) invert(1);
    }
  }
}
.addBeneficiary {
  .beneficiaryForm {
    margin-top: 30px;
    button {
      margin-top: 25px !important;
      display: inline-block;
      width: 200px;
      margin: 0 auto;
      max-width: 200px;
      display: flex;
    }
  }
}
.DepositContent {
  padding: 32px;
  display: flex;
  flex-direction: column;

  @media (max-width: 767px) {
    padding: 15px;
  }

  &_bankList {
    background-color: #7b7b7b0f;
    padding: 28px 32px;
    border-radius: 20px;

    @media (max-width: 767px) {
      padding: 15px;
    }

    li {
      margin-bottom: 15px;

      &:last-child {
        margin-bottom: 0;
      }

      span {
        @include fluid-type(320px, 1920px, 12px, 14px);

        &:first-child {
          color: #80838e;
        }
      }
    }
  }

  p {
    padding: 16px 0 32px 0;
    @include fluid-type(320px, 1920px, 12px, 14px);
    color: #000000;
  }

  button {
    width: 100%;
    color: white;
  }
}

.QApart {
  border: 1px solid #e3e3e3;
  border-radius: 20px;
  padding: 32px;
  height: 100%;

  @media (max-width: 767px) {
    padding: 15px;
  }

  h4 {
    @include fluid-type(320px, 1920px, 16px, 20px);
    font-weight: 600;
    padding-bottom: 18px;
  }

  &_content {
    padding-bottom: 24px;

    h5 {
      @include fluid-type(320px, 1920px, 14px, 16px);
      font-weight: 500;
    }

    p {
      @include fluid-type(320px, 1920px, 12px, 14px);
      font-weight: 400;
      color: #80838e;
    }
  }
}

.bar {
  background-color: #7b7b7b0f;
  padding: 12px 32px;

  h4 {
    font-weight: 600;

    span {
      font-weight: 400;
      margin-right: 5px;
    }

    img {
      margin: 0 5px;
    }
  }
}
.barCodeImage {
  display: flex;
  justify-content: center;
  margin: 10px 0;
  img {
    width: 200px;
    aspect-ratio: 1;
  }
}
.amountWithcurncy {
  position: relative;
  .crncyName {
    position: absolute;
    bottom: 0px;
    right: 2px;
    padding: 9px;
    border-left: 2px solid #ddd;
  }
}
.withdrawInfo {
  margin-bottom: 20px;
  display: flex;
  flex-wrap: wrap;
  p {
    padding: 8px 0 0;
    @include fluid-type(320px, 1920px, 12px, 14px);
    color: #f0b90b;
    flex: 0 0 50%;
    max-width: 500%;
    b {
      font-weight: 400;
      color: #000000;
    }
    &.avlBls {
      text-align: left;
      flex: 0 0 100%;
      max-width: 100%;
    }
  }
}
.withdrawBtn {
  display: inline-block;
  width: fit-content;
  margin-left: auto;
}
