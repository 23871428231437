@import "../../Assets/Styles/_vars.scss";
@import "../../Assets/Styles/_mixins.scss";
.checkboxCustom {
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: $CommonButton;
    border-color: $CommonButton;
  }
  .ant-checkbox-input:focus + .ant-checkbox-inner,
  .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner {
    border-color: $CommonButton !important;
  }
  .ant-checkbox-checked::after {
    border: 1px solid $CommonButton;
  }
  .ant-checkbox-checked .ant-checkbox-inner::after {
    border-color: $white;
  }
  .checkboxTxt {
    @include fluid-type(320px, 1920px, 12px, 14px);
    color: $checkboxparagraph;
    padding-left: 0px;
    p {
      @include fluid-type(320px, 1920px, 12px, 14px);
    }
    &:hover {
      border-color: $CommonButton;
    }
  }
  .ant-checkbox-inner {
    background-color: $white;
  }
}

.ant-checkbox-input:focus + .ant-checkbox-inner,
.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner {
  border-color: $CommonButton !important;
}
