@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&display=swap");
// @import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,900;1,100;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
$baseFontRegular: "Open Sans", sans-serif;
$baseFontBold: "Open Sans", sans-serif;
$darktext: #000000;
// $white: #000000;
$lightbgyellow: #fff4c7;
$baseblue: #f0b90b;
$baseblue1:#e7d086;
$base-hover: #f0b90bc7;
$bordergrey: #bcbcbc50;
$pendingtext: #e5862f;
$portfoliobg: #f7f9fd;
$borderdarkgrey: #6B6B6B;
$bgderkgrey: #f3f3f3;
$grey-707070: #707070;
$text-80838E: #80838e;
$bg-icon-80838E: rgba(128, 131, 142, 0.1);
$green-098551: #098551;
$tableborders: #00000012;
$bg-light-FBFCFF: #fbfcff;
$bgrewardata:#F8F8F8;
$bgblue-ffffff4a:#ffffff4a;
$bg-light-gray-F3F3F3: #f3f3f3;
$bg-light-blue-E9F5FF: #e4bd2233;
$bg-totalearning:#BDCEF0;
$bg-totalearning1:#FAFCFF00;
$border-light-gray-F3F3F3: #f3f3f3; // Dual Theme control mode start
$staticBorder: $bordergrey;
$CommonButton: $baseblue;
$checkboxparagraph: black;
$portfolioHead: #faf2d2;
$btnTextLight: $text-80838E;
$link: #f0b90b;
$lightblue-F5F8FF: #e4bd2233;
$white: white;
$red: #cf0038;
$red-CF304A: #cf304a;
$tabletext: #1b1c1e;
$green: #12b764;
$tabletext: #1b1c1e;
$tabletext: #1b1c1e;
$blue-F1F5FF:#F1F5FF;
$blue-F3F7FF:#F3F7FF;

// Dual Theme control mode start

$themes: (
  lightTheme: ("securityBtn": #dfe3ec,
    "darkWhite": #000,
    "darkWhiteReverse": #fff,
  ),
  darkTheme: ("securityBtn": #282c34,
    "darkWhite": #fff,
    "darkWhiteReverse": #000,
  ),
);