@import "../../Assets/Styles/_vars.scss";
@import "../../Assets/Styles/_mixins.scss";

.SelectOuter {
  position: relative;

  .innerTxtField {
    position: absolute;
    top: 13px;
    left: 16px;
    color: $checkboxparagraph !important;
  }

  .label {
    letter-spacing: 0px;
    color: $darktext;
    font-weight: 400;
    opacity: 1;
    width: 100%;
    text-align: left;
    @include fluid-type(320px, 1920px, 12px, 14px);
    display: flex;
    // padding-top: 3px;
    padding-bottom: 8px;
  }

  .ant-select.customSelect.paddingLeft {
    .ant-select-selector {
      padding-left: 80px !important;
    }
  }
}

.customSelect.ant-select {
  border: 1px solid $bordergrey;
  border-radius: 50px;
  color: $checkboxparagraph !important;
  @include fluid-type(320px, 1920px, 12px, 14px);
  width: 100%;
  background-color: $white;
  height: 40px;
  margin-bottom: 10px;
  @media (max-width: 585px) {
    height: 35px;
  }

  img {
    margin-right: 20px;
  }

  .ant-select-arrow {
    display: none;
  }

  position: relative;

  &::after {
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
    width: 9px;
    height: 6px;
    content: "";
    background-image: url("../../Assets/Images/dropicon.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
  }

  &-open {
    &::after {
      transform: rotate(180deg);
    }
  }

  .ant-select-item,
  .ant-select-selection-item {
    background-color: transparent !important;
    color: black !important;
    line-height: 19px !important;
    @media (max-width: 585px) {
      line-height: 13px !important;
    }
  }

  .ant-select-selector {
    padding: 10px !important;
    background: transparent !important;
    border: 0px solid !important;
    border-radius: 4px !important;
    z-index: 1;
    // height: 45px !important;
    border-color: $staticBorder !important;
    color: $checkboxparagraph !important;

    &:hover,
    &:focus,
    &:focus-visible,
    &:focus-within {
      border-color: $CommonButton !important;
      outline: none !important;
      box-shadow: none !important;
    }
    .ant-select-selection-search{
      height: 40px;
      input{
        height: 40px;
      }
    }
  }

  &:hover,
  &:focus,
  &:focus-visible,
  &:focus-within {
    border-color: $CommonButton !important;
    outline: none !important;
    box-shadow: none !important;
  }

  &.innerPagesSelect {
    text-align: right;
  }

  &.fillBg {
    background-color: $checkboxparagraph;
    border-radius: 24px;
  }

  &.option_select {
    border: 1px solid $checkboxparagraph !important;
    border-radius: 5px;
  }
}
.orderBookSelect.ant-select {
  margin-bottom: 0 !important;
}

.ant-select-dropdown {
  background-color: $white !important;
  box-shadow: rgb(0 0 0 / 8%) 0px 7px 14px, rgb(0 0 0 / 8%) 0px 3px 6px,
    rgb(94 102 115 / 90%) 0px 0px 1px inset !important;
  padding: 0px;

  img {
    margin-right: 20px;
  }

  .ant-select-item-option {
    color: $checkboxparagraph !important;

    &-selected,
    &:hover {
      color: white !important;
      background-color: $baseblue !important;
    }

    &:focus {
      background-color: unset !important;
    }
  }
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  // padding: 14px 16px !important;
  height: auto !important;
}

#rc_select_1 {
  height: 100%;
}

// .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
//   background-color: transparent !important;
// }

.bgcolorchnge {
  border-radius: 24px !important;
}

.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
  padding: 0;
  line-height: 20px;
  transition: all 0.3s;
}
.ant-select-selection-placeholder {
  line-height: 20px !important;
}

.lablvalu {
  position: absolute;
  left: 20px;
  top: 30px;
  font-size: 14px;
  color: $bordergrey;
}

.bordernone {
  border: none !important;
  height: 30px !important;

  .ant-select-selection-placeholder {
    color: #000;
    font-weight: 600;
    font-family: $baseFontBold;
    font-size: 16px;
  }
}
.customselectwhite {
  background-color: $white !important;
  border: none !important;
}
